import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import Spinner from "../components/Spinner";
import Scroll from "../components/Scroll";
import { CSSTransition } from "react-transition-group";

function About() {
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    // Set a timeout to trigger the animation after a delay
    const timer = setTimeout(() => {
      setInProp(true);
    }, 1000); // Delay of 1000 milliseconds (1 second)

    // Cleanup the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  /*landing page opens at the top of the page*/
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* <!-- Spinner Start --> */}
      <Spinner />
      {/* <!-- Spinner End --> */}

      {/* <!-- Topbar Start --> */}
      <div className="container-fluid bg-gold text-light px-0 py-2">
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <span className="fa fa-phone-alt me-2"></span>
              <span>+225-0574820567 / 0778751000</span>
            </div>
            <div className="h-100 d-inline-flex align-items-center">
              <span className="far fa-envelope me-2"></span>
              <span>info@imperrial.com</span>
            </div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center mx-n2">
              <span>Follow Us:</span>
              <Link to="" className="btn btn-link text-light">
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link to="" className="btn btn-link text-light">
                <i className="fab fa-twitter"></i>
              </Link>
              <Link to="" className="btn btn-link text-light">
                <i className="fab fa-linkedin-in"></i>
              </Link>
              <Link
                to="https://www.instagram.com/imperrial.2020/profilecard/?igsh=c3RkcTFuMmI2b2pq"
                className="btn btn-link text-light"
              >
                <i className="fab fa-instagram"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Topbar End --> */}

      {/* <!-- Navbar Start --> */}
      <nav className="navbar navbar-expand-lg bg-darkChocolate navbar-light sticky-top p-0">
        <Link
          to="/Home"
          className="navbar-brand d-flex align-items-center px-4 px-lg-5"
        >
          <img
            className="img-fluid"
            src="img/Imperrial_logo.jpeg"
            alt="logo"
            width="90"
            height="45"
          />
        </Link>
        <button
          type="button"
          className="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <Link to="/Home" className="nav-item nav-link">
              Home
            </Link>
            <Link to="#" className="nav-item nav-link active">
              About
            </Link>
            <Link to="/Service" className="nav-item nav-link">
              Products
            </Link>
            <Link to="/Project" className="nav-item nav-link">
              Projects
            </Link>
          </div>
          {/* Button for Web View */}
          <Link
            to="/Contact"
            className="btn btn-primary py-3 px-lg-4 rounded-0 d-none d-lg-block"
          >
            Contact Us
            <i className="fa fa-arrow-right ms-3 contactArrow"></i>
          </Link>
          {/* Button for Mobile View */}
          <Link
            to="/Contact"
            className="btn btn-primary py-4 px-lg-4 rounded-0 d-lg-none nav-item nav-link"
          >
            Contact Us<i className="fa fa-arrow-right ms-2"></i>
          </Link>
        </div>
      </nav>
      {/* <!-- Navbar End --> */}

      {/* <!-- Page Header Start --> */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <CSSTransition
            in={inProp}
            timeout={500}
            classNames="slide-in-down"
            unmountOnExit
          >
            <div>
              <h1 className="display-3 text-white mb-4 animated slideInDown">
                About Us
              </h1>
              <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb justify-content-center mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/Home">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    About
                  </li>
                </ol>
              </nav>
            </div>
          </CSSTransition>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- About Start --> */}
      <div className="container-xxl py-5 card_align">
        <div className="container">
          <div className="row g-5 align-items-end">
            <div
              className="col-lg-3 col-md-5 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <img
                className="img-fluid rounded"
                data-wow-delay="0.1s"
                src="img/experiance.jpg"
                alt="experiance"
              />
            </div>
            <div
              className="col-lg-6 col-md-7 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <h1 className="display-1 text-primary mb-0">19</h1>
              <p className="text-primary mb-4">Year of Experience</p>
              <h5 className="display-5 mb-4">
                We ensure every product undergoes rigorous quality checks and
                processing
              </h5>
              <p className="mb-4">
                For nearly two decades, we've been dedicated to sourcing and
                delivering the finest raw cashew nuts and quality dry bags. Our
                commitment to quality, purity, and customer satisfaction is
                unwavering. Trust in our experience for exceptional excellence
                in both product categories.
              </p>
            </div>
            <div
              className="col-lg-3 col-md-12 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="row g-5">
                <div className="col-12 col-sm-6 col-lg-12">
                  <div className="border-start ps-4">
                    <i className="fa fa-award fa-3x text-primary mb-3"></i>
                    <h4 className="mb-3">Member of TNCPEA</h4>
                    <span>
                      Committed to excellence as a proud member of TNCPEA.
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-12">
                  <div className="border-start ps-4">
                    <i className="fa fa-users fa-3x text-primary mb-3"></i>
                    <h4 className="mb-3">Dedicated Team</h4>
                    <span>
                      At the heart of our success, our devoted team guarantees
                      top-notch quality and customer satisfaction with every
                      product we deliver.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}

      {/* <!-- Facts Start --> */}
      <div>
        <div
          className="container-fluid facts my-5 py-5"
          style={{
            backgroundImage: "url('img/banner2.webp')",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
          }}
        >
          <div className="container py-5">
            <div className="row g-5">
              <div
                className="col-sm-6 col-lg-3 text-center wow fadeIn"
                data-wow-delay="0.1s"
              >
                <h1
                  className="display-4 text-primary counter"
                  data-toggle="counter-up"
                >
                  <CountUp start={0} end={50} duration={4} />
                </h1>
                <h1 className="display-4 text-primary counter"> +</h1>
                <h5 className="fs-5 fw-semi-bold">Happy Clients</h5>
              </div>
              <div
                className="col-sm-6 col-lg-3 text-center wow fadeIn"
                data-wow-delay="0.3s"
              >
                <h1
                  className="display-4 text-primary counter"
                  data-toggle="counter-up"
                >
                  <CountUp start={0} end={100} duration={4} />
                </h1>
                <h1 className="display-4 text-primary counter"> +</h1>
                <h5 className="fs-5 fw-semi-bold">Shipment units moved</h5>
              </div>
              <div
                className="col-sm-6 col-lg-3 text-center wow fadeIn"
                data-wow-delay="0.5s"
              >
                <h1
                  className="display-4 text-primary counter"
                  data-toggle="counter-up"
                >
                  <CountUp start={0} end={10} duration={4} />
                </h1>
                <h1 className="display-4 text-primary counter"> +</h1>
                <h5 className="fs-5 fw-semi-bold">Dedicated Staff</h5>
              </div>
              <div
                className="col-sm-6 col-lg-3 text-center wow fadeIn"
                data-wow-delay="0.7s"
              >
                <h1
                  className="display-4 text-primary counter"
                  data-toggle="counter-up"
                >
                  <CountUp start={0} end={100} duration={4} />
                </h1>
                <h1 className="display-4 text-primary counter"> +</h1>
                <h5 className="fs-5 fw-semi-bold">
                  Cargo dispatched in metric tons
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Facts End --> */}

      {/* <!-- Footer Start --> */}
      <div
        className="container-fluid bg-darkChocolate text-light footer mt-5 py-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5  footer_align">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-4">Our Office</h4>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt me-3"></i>IMPERRIAL
                INTERNATIONAL TRADING SARL
                <br></br>
                22 BP 150 Abidjan 22 3éme Pont, Carrefour CHU de Yopougon ,Near
                CORIS BANK, Abidjan (Côte d’Ivoire)
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3"></i>+225-0574820567 /
                0778751000
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-3"></i>info@imperrial.com
              </p>
              <div className="d-flex pt-2">
                <Link
                  to="https://www.instagram.com/imperrial.2020/profilecard/?igsh=c3RkcTFuMmI2b2pq"
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                >
                  <i className="fab fa-instagram"></i>
                </Link>
                <Link
                  to=""
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                >
                  <i className="fab fa-facebook-f"></i>
                </Link>
                <Link
                  to=""
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                >
                  <i className="fab fa-youtube"></i>
                </Link>
                <Link
                  to=""
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                >
                  <i className="fab fa-linkedin-in"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-4">Products</h4>
              <Link to="#" className="btn btn-link">
                Raw Cashew Nuts
              </Link>
              <Link to="#" className="btn btn-link">
                Cashew Kernels
              </Link>
              <Link to="#" className="btn btn-link">
                Turmeric Bulb and Finger
              </Link>
              <Link to="#" className="btn btn-link">
                Turmeric Powder
              </Link>
              <Link to="#" className="btn btn-link">
                Dry Bags
              </Link>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-4">Quick Links</h4>
              <Link to="/Home" className="btn btn-link">
                Home
              </Link>
              <Link to="/Service" className="btn btn-link">
                Our Project
              </Link>
              <Link to="/Service" className="btn btn-link">
                Our Products
              </Link>
              <Link to="/Contact Us" className="btn btn-link">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Footer End --> */}

      {/* <!-- Copyright Start --> */}
      <div className="container-fluid copyright py-4 footer_align">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy;{" "}
              <Link to="mailto:info@imperrial.com" className="border-bottom">
                Imperrial
              </Link>
              , All Right Reserved.
            </div>
            <div className="col-md-6 text-center text-md-end">
              {/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/}
              Designed By{" "}
              <Link
                to="mailto:support@eroedgetechnologies.com"
                className="border-bottom"
              >
                Ero Edge Technologies
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Copyright End --> */}

      {/* <!-- Back to Top --> */}
      <Scroll />
    </div>
  );
}

export default About;
