import React, { useState } from "react";

const Portfolio = () => {
  // State to track the active filter
  const [activeFilter, setActiveFilter] = useState("*");
  // State to track the active filter
  const [filterKey, setFilterKey] = useState(0);

  // Filtered portfolio items based on activeFilter state
  const filteredItems =
    activeFilter === "*"
      ? portfolioItems
      : portfolioItems.filter((item) => item.category === activeFilter);

  // Function to handle filter change
  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
    setFilterKey((prevKey) => prevKey + 1);
  };

  return (
    <div className="container-xxl py-5">
      <div className="container card_align">
        <div
          className="text-center mx-auto wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: "500px" }}
        >
          <p className="fs-5 fw-bold text-primary">Our Projects</p>
          <h1 className="display-5 mb-5">Some Of Our Wonderful Projects</h1>
        </div>
        <div className="row wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-12 text-center card_top card_bottom ">
            <ul className="list-inline rounded mb-5" id="portfolio-flters">
              <li
                className={`mx-2 ${activeFilter === "*" ? "active" : ""}`}
                onClick={() => handleFilterClick("*")}
              >
                All
              </li>
              <li
                className={`mx-2 ${activeFilter === "first" ? "active" : ""}`}
                onClick={() => handleFilterClick("first")}
              >
                Raw Cashew Nuts
              </li>
              <li
                className={`mx-2 ${activeFilter === "second" ? "active" : ""}`}
                onClick={() => handleFilterClick("second")}
              >
                Turmeric
              </li>
              <li
                className={`mx-2 ${activeFilter === "three" ? "active" : ""}`}
                onClick={() => handleFilterClick("three")}
              >
                Soybean
              </li>
              <li
                className={`mx-2 ${activeFilter === "four" ? "active" : ""}`}
                onClick={() => handleFilterClick("four")}
              >
                Dry Bag
              </li>
              <li
                className={`mx-2 ${activeFilter === "five" ? "active" : ""}`}
                onClick={() => handleFilterClick("five")}
              >
                Rubber
              </li>
              <li
                className={`mx-2 ${activeFilter === "six" ? "active" : ""}`}
                onClick={() => handleFilterClick("six")}
              >
                Sesame
              </li>
              <li
                className={`mx-2 ${activeFilter === "seven" ? "active" : ""}`}
                onClick={() => handleFilterClick("seven")}
              >
                Annatto Seed
              </li>
              <li
                className={`mx-2 ${activeFilter === "eight" ? "active" : ""}`}
                onClick={() => handleFilterClick("eight")}
              >
                White Rice -IR64
              </li>
              <li
                className={`mx-2 ${activeFilter === "nine" ? "active" : ""}`}
                onClick={() => handleFilterClick("nine")}
              >
                Seanut
              </li>
            </ul>
          </div>
        </div>
        <div className="row g-4">
          <div
            className="row g-4 wow fadeInUp"
            data-wow-delay="0.3s"
            key={filterKey}
          >
            {filteredItems.map((item, index) => (
              <div
                key={index}
                className={`col-lg-4 col-md-6 portfolio-item ${item.category} wow fadeInUp`}
                data-wow-delay="0.1s"
              >
                <div className="portfolio-inner rounded card_top">
                  <img
                    className="img-fluid"
                    src={item.image}
                    alt={item.title}
                  />
                  <div className="portfolio-text">
                    <h4 className="text-white mb-4">{item.title}</h4>
                    <div className="d-flex">
                      <a
                        className="btn btn-lg-square rounded-circle mx-2"
                        href={item.image}
                        data-lightbox="portfolio"
                      >
                        <i className="fa fa-eye"></i>
                      </a>
                      {item.link && (
                        <a
                          className="btn btn-lg-square rounded-circle mx-2"
                          href={item.link}
                        >
                          <i className="fa fa-link"></i>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// Sample data for portfolio items
const portfolioItems = [
  {
    category: "first",
    image: "img/project1.jpeg",
    title: "Loading from Formers & Co - operative Society African Orgin",
  },
  { category: "first", image: "img/project2.jpg", title: "Cargo Arrival @ WH" },
  { category: "first", image: "img/project3.jpeg", title: "Sampling" },
  { category: "first", image: "img/project4.jpeg", title: "Moisture Testing" },
  {
    category: "first",
    image: "img/project5.jpeg",
    title: "Sample Mixing & Quality Checking",
  },
  { category: "first", image: "img/project6.jpeg", title: "Sun Drying" },
  { category: "first", image: "img/project7.jpeg", title: "Warehouse Storage" },
  { category: "first", image: "img/project8.jpeg", title: "Container Loading" },
  {
    category: "first",
    image: "img/project9.jpeg",
    title: "Containers for Shipment",
  },
  { category: "second", image: "img/project10.jpeg", title: "Turmeric Finger" },
  { category: "second", image: "img/project11.jpeg", title: "Turmeric Bulb" },
  { category: "second", image: "img/products21.png", title: "Turmeric Powder" },
  { category: "three", image: "img/project14.jpeg", title: "Soybean" },
  { category: "three", image: "img/project12.jpeg", title: "Soybean" },
  { category: "four", image: "img/project15.png", title: "DryBag" },
  { category: "four", image: "img/dry_bag_2.jpeg", title: "DryBag" },
  { category: "four", image: "img/dry_bag_3.jpeg", title: "DryBag" },
  { category: "four", image: "img/dry_bag_4.jpeg", title: "DryBag" },
  { category: "five", image: "img/rubber.jpg", title: "Rubber" },
  { category: "five", image: "img/rubber_2.webp", title: "Rubber" },
  { category: "five", image: "img/rubber_3.png", title: "Rubber" },
  { category: "six", image: "img/sesame.jpg", title: "Sesame" },
  { category: "six", image: "img/sesame_3.webp", title: "Sesame" },
  { category: "six", image: "img/sesame_2.png", title: "Sesame" },
  {
    category: "seven",
    image: "img/annatto_seeds.jpeg",
    title: "Annatto Seed",
  },
  {
    category: "seven",
    image: "img/annatto_seeds_2.jpeg",
    title: "Annatto Seed",
  },
  {
    category: "eight",
    image: "img/whiterice.jpeg",
    title: "White Rice",
  },
  {
    category: "eight",
    image: "img/whiterice_2.jpeg",
    title: "White Rice",
  },
  {
    category: "eight",
    image: "img/whiterice_3.jpeg",
    title: "White Rice",
  },
  { category: "nine", image: "img/seanut.jpg", title: "Seanut" },
  { category: "nine", image: "img/seanut_2.jpg", title: "Seanut" },
  { category: "nine", image: "img/seanut_3.webp", title: "Seanut" },
];

export default Portfolio;
