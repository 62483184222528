import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import WOW from "wowjs";
import { CSSTransition } from "react-transition-group";

const SampleNextArrow = () => <span className="visually-hidden">Next</span>;

const SamplePrevArrow = () => <span className="visually-hidden">Previous</span>;

const HomepageSlider = () => {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const filteredItems = [
    {
      src: "img/banner3.webp",
      alt: "banner3",
      caption: "Discover global cashew trade with us, your trusted partner.",
    },
    {
      src: "img/carton_box_ero_dry.webp",
      alt: "carton_box_ero_dry",
      caption: "Your Go-To for Dry Bag Imports and Exports",
    },
  ];

  const handleSelect = (selectedIndex) => {
    setCurrentImageIndex(selectedIndex);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? filteredItems.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === filteredItems.length - 1 ? 0 : prevIndex + 1
    );
  };

  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    // Set a timeout to trigger the animation after a delay
    const timer = setTimeout(() => {
      setInProp(true);
    }, 1000); // Delay of 1000 milliseconds (1 second)

    // Cleanup the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="container-fluid p-0 wow fadeIn" data-wow-delay="0.1s">
      <Carousel
        activeIndex={currentImageIndex}
        onSelect={handleSelect}
        interval={4000} // Set the interval speed to 5000ms (5 seconds)
        slide={true} // Enable slide animation
      >
        {filteredItems.map((item, index) => (
          <Carousel.Item key={index}>
            <img className="w-100" src={item.src} alt={item.alt} />
            <Carousel.Caption>
              <div className="container position-relative">
                <div className="row justify-content-center">
                  <CSSTransition
                    in={inProp}
                    timeout={500}
                    classNames="slide-in-down"
                    unmountOnExit
                  >
                    <div className="col-lg-8">
                      <h1 className="display-1 text-white mb-5 animated slideInDown text_align">
                        {item.caption}
                      </h1>
                    </div>
                  </CSSTransition>
                </div>
                <SamplePrevArrow onClick={handlePrevImage} />
                <SampleNextArrow onClick={handleNextImage} />
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default HomepageSlider;
