import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";
import Scroll from "../components/Scroll";
import { CSSTransition } from "react-transition-group";

function Contact() {
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    // Set a timeout to trigger the animation after a delay
    const timer = setTimeout(() => {
      setInProp(true);
    }, 1000); // Delay of 1000 milliseconds (1 second)

    // Cleanup the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  /*landing page opens at the top of the page*/
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* <!-- Spinner Start --> */}
      <Spinner />
      {/* <!-- Spinner End --> */}

      {/* <!-- Topbar Start --> */}
      <div className="container-fluid bg-gold text-light px-0 py-2">
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <span className="fa fa-phone-alt me-2"></span>
              <span>+225-0574820567 / 0778751000</span>
            </div>
            <div className="h-100 d-inline-flex align-items-center">
              <span className="far fa-envelope me-2"></span>
              <span>info@imperrial.com</span>
            </div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center mx-n2">
              <span>Follow Us:</span>
              <Link to="#" className="btn btn-link text-light">
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link to="#" className="btn btn-link text-light">
                <i className="fab fa-twitter"></i>
              </Link>
              <Link to="#" className="btn btn-link text-light">
                <i className="fab fa-linkedin-in"></i>
              </Link>
              <Link
                to="https://www.instagram.com/imperrial.2020/profilecard/?igsh=c3RkcTFuMmI2b2pq"
                className="btn btn-link text-light"
              >
                <i className="fab fa-instagram"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Topbar End --> */}

      {/* <!-- Navbar Start --> */}
      <nav className="navbar navbar-expand-lg bg-darkChocolate navbar-light sticky-top p-0">
        <Link
          to="/Home"
          className="navbar-brand d-flex align-items-center px-4 px-lg-5"
        >
          <img
            className="img-fluid"
            src="img/Imperrial_logo.jpeg"
            alt="logo"
            width="90"
            height="45"
          />
        </Link>
        <button
          type="button"
          className="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <Link to="/Home" className="nav-item nav-link">
              Home
            </Link>
            <Link to="/About" className="nav-item nav-link">
              About
            </Link>
            <Link to="/Service" className="nav-item nav-link">
              Products
            </Link>
            <Link to="/Project" className="nav-item nav-link">
              Projects
            </Link>
          </div>
        </div>
      </nav>
      {/* <!-- Navbar End --> */}

      {/* <!-- Page Header Start --> */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <CSSTransition
            in={inProp}
            timeout={500}
            classNames="slide-in-down"
            unmountOnExit
          >
            <div>
              <h1 className="display-3 text-white mb-4 animated slideInDown">
                Contact Us
              </h1>
              <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb justify-content-center mb-0">
                  <li className="breadcrumb-item">
                    <a href="/Home">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Contact
                  </li>
                </ol>
              </nav>
            </div>
          </CSSTransition>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- Contact Start --> */}
      <div className="container-xxl py-5 map_align">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <p className="fs-5 fw-bold text-primary card_align">Contact Us</p>
              <h1 className="display-5 mb-5">
                If You Have Any Query, Please Contact Us
              </h1>
              <div className="row">
                <div className="col-12 col-md-6 mb-4">
                  <div className="director-info">
                    <h4>MR. Sankar Nagarajan</h4>
                    <p>CEO</p>
                    <p>
                      <i className="fas fa-envelope"></i> info@imperrial.com
                    </p>
                    <p>
                      <i className="fab fa-whatsapp"></i> +91 90951-44773
                    </p>
                    <p>
                      <i className="fas fa-phone"></i> +225 0778751000
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 wow fadeIn map_align map_height"
              data-wow-delay="0.5s"
            >
              <div className="position-relative rounded overflow-hidden h-100">
                <iframe
                  width="100%"
                  height="100%"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=5.3596166,-4.0133602+%28Imperrial%29&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  title="Address"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Contact End --> */}

      {/* Footer Start  */}
      <div className="footer_align">
        <div
          className="container-fluid bg-darkChocolate text-light footer mt-5 py-5 wow fadeIn"
          data-wow-delay="0.1s"
        >
          <div className="container py-5 footer_align">
            <div className="row g-5">
              <div className="col-lg-3 col-md-6">
                <h4 className="text-white mb-4">Our Office</h4>
                <p className="mb-2">
                  <i className="fa fa-map-marker-alt me-3"></i>IMPERRIAL
                  INTERNATIONAL TRADING SARL
                  <br></br>
                  22 BP 150 Abidjan 22 3éme Pont, Carrefour CHU de Yopougon
                  ,Near CORIS BANK, Abidjan (Côte d’Ivoire)
                </p>
                <p className="mb-2">
                  <i className="fa fa-phone-alt me-3"></i>+225-0574820567 /
                  0778751000
                </p>
                <p className="mb-2">
                  <i className="fa fa-envelope me-3"></i>info@imperrial.com
                </p>
                <div className="d-flex pt-2">
                  <Link
                    to="https://www.instagram.com/imperrial.2020/profilecard/?igsh=c3RkcTFuMmI2b2pq"
                    className="btn btn-square btn-outline-light rounded-circle me-2"
                  >
                    <i className="fab fa-instagram"></i>
                  </Link>
                  <Link
                    to=""
                    className="btn btn-square btn-outline-light rounded-circle me-2"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                  <Link
                    to=""
                    className="btn btn-square btn-outline-light rounded-circle me-2"
                  >
                    <i className="fab fa-youtube"></i>
                  </Link>
                  <Link
                    to=""
                    className="btn btn-square btn-outline-light rounded-circle me-2"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className="text-white mb-4">Products</h4>
                <Link to="" className="btn btn-link">
                  Raw Cashew Nuts
                </Link>
                <Link to="" className="btn btn-link">
                  Cashew Kernels
                </Link>
                <Link to="" className="btn btn-link">
                  Turmeric Bulb and Finger
                </Link>
                <Link to="" className="btn btn-link">
                  Turmeric Powder
                </Link>
                <Link to="" className="btn btn-link">
                  Dry Bags
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className="text-white mb-4">Quick Links</h4>
                <Link to="Contact" className="btn btn-link">
                  Home
                </Link>
                <Link to="/Project" className="btn btn-link">
                  Our Projects
                </Link>
                <Link to="/Service" className="btn btn-link">
                  Our Products
                </Link>
                <Link to="/About" className="btn btn-link">
                  About Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End  */}

      {/* <!-- Copyright Start --> */}
      <div className="container-fluid copyright py-4 footer_align">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy;{" "}
              <Link to="mailto:info@imperrial.com" className="border-bottom">
                Imperrial
              </Link>
              , All Right Reserved.
            </div>
            <div className="col-md-6 text-center text-md-end">
              Designed By{" "}
              <Link
                to="mailto:support@eroedgetechnologies.com"
                className="border-bottom"
              >
                Ero Edge Technologies
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Copyright End --> */}

      {/* <!-- Back to Top --> */}
      <Scroll />
    </div>
  );
}

export default Contact;
