import React, { useState, useEffect, useCallback } from "react";

function Scroll({ isModalOpen }) {
  const [isVisible, setIsVisible] = useState(false);

  // Function to check scroll position and update visibility
  const checkScrollVisibility = useCallback(() => {
    if (window.pageYOffset > 300 && !isModalOpen) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [isModalOpen]);

  // Scroll to the top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Effect to add/remove scroll listener and handle modal state changes
  useEffect(() => {
    const handleScroll = () => {
      checkScrollVisibility();
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Initial check and whenever modal state changes
    checkScrollVisibility();

    // Clean up: remove scroll event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [checkScrollVisibility]);

  return (
    <div>
      {isVisible && (
        <button
          className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"
          onClick={scrollToTop}
        >
          <i className="bi bi-arrow-up"></i>
        </button>
      )}
    </div>
  );
}

export default Scroll;
