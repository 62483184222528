import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";
import Scroll from "../components/Scroll";
import { CSSTransition } from "react-transition-group";

function Service() {
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    // Set a timeout to trigger the animation after a delay
    const timer = setTimeout(() => {
      setInProp(true);
    }, 1000); // Delay of 1000 milliseconds (1 second)

    // Cleanup the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  /*landing page opens at the top of the page*/
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* <!-- Spinner Start --> */}
      <Spinner />
      {/* <!-- Spinner End --> */}

      {/* <!-- Topbar Start --> */}
      <div className="container-fluid bg-gold text-light px-0 py-2">
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <span className="fa fa-phone-alt me-2"></span>
              <span>+225-0574820567 / 0778751000</span>
            </div>
            <div className="h-100 d-inline-flex align-items-center">
              <span className="far fa-envelope me-2"></span>
              <span>info@imperrial.com</span>
            </div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center mx-n2">
              <span>Follow Us:</span>
              <Link to="" className="btn btn-link text-light">
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link to="" className="btn btn-link text-light">
                <i className="fab fa-twitter"></i>
              </Link>
              <Link to="" className="btn btn-link text-light">
                <i className="fab fa-linkedin-in"></i>
              </Link>
              <Link
                to="https://www.instagram.com/imperrial.2020/profilecard/?igsh=c3RkcTFuMmI2b2pq"
                className="btn btn-link text-light"
              >
                <i className="fab fa-instagram"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Topbar End --> */}

      {/* <!-- Navbar Start --> */}
      <nav className="navbar navbar-expand-lg bg-darkChocolate navbar-light sticky-top p-0">
        <Link
          to="/Home"
          className="navbar-brand d-flex align-items-center px-4 px-lg-5"
        >
          <img
            className="img-fluid"
            src="img/Imperrial_logo.jpeg"
            alt="logo"
            width="90"
            height="45"
          />
        </Link>
        <button
          type="button"
          className="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <Link to="/Home" className="nav-item nav-link">
              Home
            </Link>
            <Link to="/About" className="nav-item nav-link">
              About
            </Link>
            <Link to="#" className="nav-item nav-link active">
              Products
            </Link>
            <Link to="/Project" className="nav-item nav-link">
              Projects
            </Link>
          </div>
          {/* Button for Web View */}
          <Link
            to="/Contact"
            className="btn btn-primary py-3 px-lg-4 rounded-0 d-none d-lg-block"
          >
            Contact Us
            <i className="fa fa-arrow-right ms-3 contactArrow"></i>
          </Link>
          {/* Button for Mobile View */}
          <Link
            to="/Contact"
            className="btn btn-primary py-4 px-lg-4 rounded-0 d-lg-none nav-item nav-link"
          >
            Contact Us<i className="fa fa-arrow-right ms-2"></i>
          </Link>
        </div>
      </nav>
      {/* <!-- Navbar End --> */}

      {/* <!-- Page Header Start --> */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <CSSTransition
            in={inProp}
            timeout={500}
            classNames="slide-in-down"
            unmountOnExit
          >
            <div>
              <h1 className="display-3 text-white mb-4 animated slideInDown">
                Products
              </h1>
              <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb justify-content-center mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/Home">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Products
                  </li>
                </ol>
              </nav>
            </div>
          </CSSTransition>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- Service Start --> */}
      <div className="container-xxl py-5">
        <div className="container card_align">
          <div
            className="text-center mx-auto wow fadeInUp service_h1_width"
            data-wow-delay="0.1s"
          >
            <p className="fs-5 fw-bold text-primary">Our Products</p>
            <h1 className="display-5 mb-5">Products That We Offer For You</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item rounded d-flex h-100">
                <div className="service-img rounded">
                  <img className="img-fluid" src="img/raw_nut.webp" alt="" />
                </div>
                <div className="service-text rounded p-5">
                  <div className="btn-square rounded-circle mx-auto mb-3">
                    <img
                      className="img-fluid"
                      src="img/products1.png"
                      alt="Icon"
                    />
                  </div>
                  <h4 className="mb-3">Raw Cashew Nuts</h4>
                  <p className="mb-4">
                    Discover the excellence of our direct raw cashew nut imports
                    and exports from African origins. With almost two decades of
                    industry experience, we're your reliable choice for premium
                    cashews, offering a direct link to diverse sources across
                    the African continent. Count on us for unparalleled quality
                    and dependable sourcing.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item rounded d-flex h-100">
                <div className="service-img rounded">
                  <img className="img-fluid" src="img/products2.png" alt="" />
                </div>
                <div className="service-text rounded p-5">
                  <div className="btn-square rounded-circle mx-auto mb-3">
                    <img
                      className="img-fluid"
                      src="img/products2.png"
                      alt="Icon"
                    />
                  </div>
                  <h4 className="mb-3">Cashew Kernels</h4>
                  <p className="mb-4">
                    Discover our diverse selection of cashew kernels, where
                    quality meets versatility. We excel in exporting cashew
                    kernels of all sizes, providing a wide range to match your
                    unique requirements. Whether you need whole cashews, splits,
                    or pieces, we've got you covered. As the direct
                    manufacturers of cashew kernels, our commitment to quality
                    and global reach ensures the perfect choice for your cashew
                    kernel exports.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item rounded d-flex h-100">
                <div className="service-img rounded">
                  <img className="img-fluid" src="img/banner2.webp" alt="" />
                </div>
                <div className="service-text rounded p-5">
                  <div className="btn-square rounded-circle mx-auto mb-3">
                    <img
                      className="img-fluid"
                      src="img/products3.png"
                      alt="Icon"
                    />
                  </div>
                  <h4 className="mb-3">Dry Bags</h4>
                  <p className="mb-4">
                    Enhance Container Cargo Protection with Our Dry Bags: Ensure
                    Your Shipment Stays Safe and Dry. Our top-tier dry bags
                    offer the ultimate solution for safeguarding your precious
                    cargo during transit. Count on our in-house manufacturing
                    for high-quality dry bags that effectively shield your goods
                    from moisture and humidity, providing reliable container
                    protection.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item rounded d-flex h-100">
                <div className="service-img rounded">
                  <img className="img-fluid" src="img/product6.png" alt="" />
                </div>
                <div className="service-text rounded p-5">
                  <div className="btn-square rounded-circle mx-auto mb-3">
                    <img
                      className="img-fluid"
                      src="img/product5.png"
                      alt="Icon"
                    />
                  </div>
                  <h4 className="mb-3">Turmeric Bulb and Finger</h4>
                  <p className="mb-4">
                    A Direct Connection to Nature's Richness. Our turmeric
                    offerings, sourced directly from local farmers, embody the
                    vibrancy of the earth. With an emphasis on quality, we bring
                    you the finest turmeric bulbs and fingers to elevate your
                    culinary adventures. Experience the authenticity and
                    goodness of our direct-from-farm sourcing.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item rounded d-flex h-100">
                <div className="service-img rounded">
                  <img className="img-fluid" src="img/products4.png" alt="" />
                </div>
                <div className="service-text rounded p-5">
                  <div className="btn-square rounded-circle mx-auto mb-3">
                    <img
                      className="img-fluid"
                      src="img/product6.png"
                      alt="Icon"
                    />
                  </div>
                  <h4 className="mb-3">Turmeric Powder</h4>
                  <p className="mb-4">
                    Pure Turmeric Powder: Crafted with Care by Our Own
                    Expertise. Our turmeric powder, meticulously crafted
                    in-house, is a testament to our commitment to quality. With
                    complete control over the manufacturing process, we ensure
                    the finest turmeric powder that's ready to infuse your
                    dishes with nature's goodness.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item rounded d-flex h-100">
                <div className="service-img rounded">
                  <img className="img-fluid" src="img/product7.png" alt="" />
                </div>
                <div className="service-text rounded p-5">
                  <div className="btn-square rounded-circle mx-auto mb-3">
                    <img
                      className="img-fluid"
                      src="img/product7.png"
                      alt="Icon"
                    />
                  </div>
                  <h4 className="mb-3">Soybean</h4>
                  <p className="mb-4">
                    Soybean Excellence: Sourced Directly from African Farmers.
                    Our soybeans, sourced directly from African farmers, embody
                    the richness of the land. We take pride in supporting local
                    communities and bringing you the finest soybeans, ensuring
                    quality and authenticity from the heart of Africa.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item rounded d-flex h-100">
                <div className="service-img rounded">
                  <img className="img-fluid" src="img/rubber_2.webp" alt="" />
                </div>
                <div className="service-text rounded p-5">
                  <div className="btn-square rounded-circle mx-auto mb-3">
                    <img
                      className="img-fluid"
                      src="img/rubber_2_modified.png"
                      alt="Icon"
                    />
                  </div>
                  <h4 className="mb-3">Rubber</h4>
                  <p className="mb-4">
                    Rubber: Our rubber is sourced with a commitment to
                    sustainability and quality. We work closely with local
                    farmers to ensure that our rubber meets the highest
                    standards while promoting environmentally friendly
                    practices. This natural product not only supports
                    livelihoods but also provides durable solutions for various
                    applications, reflecting our dedication to ethical sourcing.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item rounded d-flex h-100">
                <div className="service-img rounded">
                  <img className="img-fluid" src="img/sesame_3.webp" alt="" />
                </div>
                <div className="service-text rounded p-5">
                  <div className="btn-square rounded-circle mx-auto mb-3">
                    <img
                      className="img-fluid"
                      src="img/sesame_2_modified.png"
                      alt="Icon"
                    />
                  </div>
                  <h4 className="mb-3">Sesame</h4>
                  <p className="mb-4">
                    Sesame: Our sesame seeds are carefully sourced to ensure the
                    highest quality and flavor. Rich in essential nutrients and
                    antioxidants, these tiny seeds are perfect for enhancing
                    your culinary creations. We prioritize sustainable farming
                    practices, supporting local communities and providing you
                    with seeds that bring both taste and health to your table.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item rounded d-flex h-100">
                <div className="service-img rounded">
                  <img
                    className="img-fluid"
                    src="img/annatto_seeds.jpeg"
                    alt=""
                  />
                </div>
                <div className="service-text rounded p-5">
                  <div className="btn-square rounded-circle mx-auto mb-3">
                    <img
                      className="img-fluid"
                      src="img/annatto_seeds_2_modified.png"
                      alt="Icon"
                    />
                  </div>
                  <h4 className="mb-3">Annatto Seed</h4>
                  <p className="mb-4">
                    Annatto Seed: Our annatto seeds are sourced with care,
                    bringing a burst of natural color and flavor to your dishes.
                    Known for their distinctive hue, these seeds are perfect for
                    adding a warm, earthy touch to culinary creations. We focus
                    on quality and sustainability, ensuring that each batch
                    reflects the richness of the land and supports local farming
                    communities.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item rounded d-flex h-100">
                <div className="service-img rounded">
                  <img className="img-fluid" src="img/whiterice.png" alt="" />
                </div>
                <div className="service-text rounded p-5">
                  <div className="btn-square rounded-circle mx-auto mb-3">
                    <img
                      className="img-fluid"
                      src="img/whiterice_modified.png"
                      alt="Icon"
                    />
                  </div>
                  <h4 className="mb-3">White Rice - IR64</h4>
                  <p className="mb-4">
                    White Rice - IR64: Our IR64 parboiled rice is carefully
                    processed to enhance its nutritional value and flavor.
                    Sourced from trusted farmers, this rice retains essential
                    nutrients while offering a delightful texture that pairs
                    perfectly with any meal. Our commitment to quality ensures
                    that you receive a product that not only supports local
                    agriculture but also elevates your culinary creations. Enjoy
                    the wholesome goodness of our IR64 parboiled rice, crafted
                    with care for your kitchen.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item rounded d-flex h-100">
                <div className="service-img rounded">
                  <img className="img-fluid" src="img/seanut_3.webp" alt="" />
                </div>
                <div className="service-text rounded p-5">
                  <div className="btn-square rounded-circle mx-auto mb-3">
                    <img
                      className="img-fluid"
                      src="img/seanut_3_modified.png"
                      alt="Icon"
                    />
                  </div>
                  <h4 className="mb-3">Seanut</h4>
                  <p className="mb-4">
                    Seanut: Our seanut are selected for their exceptional
                    quality and taste. Sourced from trusted local farmers, these
                    peanuts embody the essence of the region, providing a
                    delightful crunch and rich flavor. We are dedicated to
                    supporting sustainable agriculture while delivering a
                    product that enhances your recipes and snacks with nature's
                    goodness.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Service End --> */}

      {/* Footer Start  */}
      <div className="footer_align">
        <div
          className="container-fluid bg-darkChocolate text-light footer mt-5 py-5 wow fadeIn"
          data-wow-delay="0.1s"
        >
          <div className="container py-5 footer_align">
            <div className="row g-5">
              <div className="col-lg-3 col-md-6">
                <h4 className="text-white mb-4">Our Office</h4>
                <p className="mb-2">
                  <i className="fa fa-map-marker-alt me-3"></i>IMPERRIAL
                  INTERNATIONAL TRADING SARL
                  <br></br>
                  22 BP 150 Abidjan 22 3éme Pont, Carrefour CHU de Yopougon
                  ,Near CORIS BANK, Abidjan (Côte d’Ivoire)
                </p>
                <p className="mb-2">
                  <i className="fa fa-phone-alt me-3"></i>
                  +225-0574820567 / 0778751000
                </p>
                <p className="mb-2">
                  <i className="fa fa-envelope me-3"></i>info@imperrial.com
                </p>
                <div className="d-flex pt-2">
                  <Link
                    to="https://www.instagram.com/imperrial.2020/profilecard/?igsh=c3RkcTFuMmI2b2pq"
                    className="btn btn-square btn-outline-light rounded-circle me-2"
                  >
                    <i className="fab fa-instagram"></i>
                  </Link>
                  <Link
                    to=""
                    className="btn btn-square btn-outline-light rounded-circle me-2"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                  <Link
                    to=""
                    className="btn btn-square btn-outline-light rounded-circle me-2"
                  >
                    <i className="fab fa-youtube"></i>
                  </Link>
                  <Link
                    to=""
                    className="btn btn-square btn-outline-light rounded-circle me-2"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className="text-white mb-4">Products</h4>
                <Link to="" className="btn btn-link">
                  Raw Cashew Nuts
                </Link>
                <Link to="" className="btn btn-link">
                  Cashew Kernels
                </Link>
                <Link to="" className="btn btn-link">
                  Turmeric Bulb and Finger
                </Link>
                <Link to="" className="btn btn-link">
                  Turmeric Powder
                </Link>
                <Link to="" className="btn btn-link">
                  Dry Bags
                </Link>
              </div>
              <div className="col-lg-3 col-md-6">
                <h4 className="text-white mb-4">Quick Links</h4>
                <Link to="/Home" className="btn btn-link">
                  Home
                </Link>
                <Link to="/Project" className="btn btn-link">
                  Projects
                </Link>
                <Link to="/About" className="btn btn-link">
                  About Us
                </Link>
                <Link to="/Contact" className="btn btn-link">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End  */}

      {/* Copyright Start  */}
      <div className="container-fluid copyright py-4 footer_align">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy;{" "}
              <Link to="mailto:info@imperrial.com" className="border-bottom">
                Imperrial
              </Link>
              , All Right Reserved.
            </div>
            <div className="col-md-6 text-center text-md-end">
              {/* This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. */}
              Designed By{" "}
              <Link
                to="mailto:support@eroedgetechnologies.com"
                className="border-bottom"
              >
                Ero Edge Technologies
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Copyright End */}

      {/* Back to Top  */}
      <Scroll />
    </div>
  );
}

export default Service;
